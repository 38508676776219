import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Button,
} from "@material-tailwind/react";
import { FingerPrintIcon } from "@heroicons/react/24/solid";
import { PageTitle } from "../components/PageTitle";
import { FeatureCard } from "../components/FeatureCard";
import { TeamCard } from "../components/TeamCard";

import {
  CheckBadgeIcon,
  LockClosedIcon,
  RocketLaunchIcon,
  PhotoIcon,
  ChatBubbleLeftIcon,
  QuestionMarkCircleIcon

} from "@heroicons/react/24/solid";


import cardImage from '../images/index-card-image.jpg'
import boy1 from '../images/boy-1.jpg'
import boy2 from '../images/boy-2.jpg'
import girl1 from '../images/girl-1.jpg'
import girl2 from '../images/girl-2.jpg'
import MemberFeature from "../components/MemberFeature";
import { ModalSignUp } from "../components/ModalSignUp";
import { ModalSignIn } from "../components/ModalSignIn";
import { PricingSection } from "../components/PricingSection";
const featuresData = [
  {
    color: "gray",
    title: "會員認證",
    icon: CheckBadgeIcon,
    description:
      "我們重視會員安全，實施嚴格的會員認證機制，確保每位會員身份真實可靠，讓你安心尋找真愛，避免虛假帳號的困擾。",
  },
  {
    color: "gray",
    title: "私隱度高",
    icon: LockClosedIcon,
    description:
      "你的個人資料安全至上，我們提供多重隱私保護措施，讓你掌控個人資訊的公開程度，安心交友，無需擔心隱私外洩。",
  },
  {
    color: "gray",
    title: "方便快捷",
    icon: RocketLaunchIcon,
    description:
      "簡單易用的介面，讓你輕鬆瀏覽會員資料，快速找到心儀對象。無論是手機或電腦，都能隨時隨地輕鬆使用，開啟你的交友旅程。",
  },
];


const contactData = [
  {
    title: "聊天室",
    icon: ChatBubbleLeftIcon,
    description:
      "提供聊天室功能，方便用戶與心儀對象進行私密交流。",
  },
  {
    title: "照片牆",
    icon: PhotoIcon,
    description:
      "允許用戶上傳照片，展示自己的風采，吸引更多關注。",
  },
  {
    title: "客服支持",
    icon: QuestionMarkCircleIcon,
    description:
      "提供專業的客服支持，解決用戶在使用過程中的問題，確保安全和順暢的體驗。",
  },
];
const teamData = [
  {
    img: boy1,
    name: "Ryan",
    position: "之前一直搵唔到合適嘅對象，喺朋友推薦下，試吓用princessbookhk.com。網站會員資料真實可靠，而且客服人員非常熱心，解答咗我好多問題。",

  },
  {
    img: girl1,
    name: "Kris",
    position: "我係一個比較怕羞嘅人，唔太敢主動同人交朋友。princessbookhk.com嘅聊天室功能令我可以輕鬆咁同人交流，而且網站嘅隱私保護措施做得好好，好安心。",

  },
  {
    img: boy2,
    name: "Max",
    position: "呢到嘅女仔好少係傳銷或者保險，終於可以同真係高質既女仔傾到計，唔使再比人哂時間同埋呃，而且一個制就拎到contact，真係好方便，十分推薦!",

  },
  {
    img: girl2,
    name: "Jenna",
    position: "坦白講，我一開始都唔太信work唔work，但有好多朋友都推薦，我就試吓啦。點知真係有好多有錢有教養嘅男仔係呢個網到，開啟我視野同終於可以享受人生。",

  },
];

export function Home() {
  return (
    <>
      <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
        <div className="absolute top-0 h-full w-full bg-index-cover bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="max-w-8xl container relative mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
              <Typography
                variant="h1"
                color="white"
                className="mb-6 font-black"
              >
                速配交友，怦然心動。
              </Typography>
              <Typography variant="lead" color="white" className="opacity-80">
                為忙碌的都市男女打造的速配交友平台， 我們提供多種主題的速配活動，讓你快速認識志趣相投的單身男女，節省時間，提高效率。
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-8 bg-white px-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {featuresData.map(({ color, title, icon, description }) => (
              <FeatureCard
                key={title}
                color={color}
                title={title}
                icon={React.createElement(icon, {
                  className: "w-5 h-5 text-white",
                })}
                description={description}
              />
            ))}
          </div>
          <div className="mt-32 flex flex-wrap items-center">
            <div className="mx-auto -mt-8 w-full px-4 md:w-5/12">
              <div className="mb-6 inline-flex h-16 w-16 items-center justify-center rounded-full bg-blue-gray-900 p-2 text-center shadow-lg">
                <FingerPrintIcon className="h-8 w-8 text-white " />
              </div>
              <Typography
                variant="h3"
                className="mb-3 font-bold"
                color="blue-gray"
              >
                效率速配
              </Typography>
              <Typography className="mb-8 font-normal text-blue-gray-500">
                在這裡，你將體驗緊張刺激的速配氛圍， 與多位心儀對象進行簡短而深刻的交流，並在活動結束後獲得更多互動機會。
                <br />
                <br />
                我們提供完善的活動安排和安全保障， 讓你安心參與速配活動，輕鬆邂逅真愛。
              </Typography>
              <Button variant="filled">立即註冊</Button>
            </div>
            <div className="mx-auto mt-24 flex w-full justify-center px-4 md:w-1/2 lg:w-1/3 lg:mt-0">
              <Card className="shadow-lg border shadow-gray-500/10 rounded-lg">
                <CardHeader floated={false} className="relative h-56">
                  <img
                    alt="Card Image"
                    src={cardImage}
                    className="h-full w-full"
                  />
                </CardHeader>
                <CardBody>
                  <Typography variant="small" color="blue-gray" className="font-normal">限時邂逅</Typography>
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-3 mt-2 font-bold"
                  >
                    開啟幸福
                  </Typography>
                  <Typography className="font-normal text-blue-gray-500">
                    我們提供精心設計的速配活動，讓你快速認識志趣相投的單身男女，節省時間，提高效率。
                  </Typography>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-8 px-4">
        <div className="container mx-auto">
          <PageTitle section=" " heading="會員評價">
            看看其他會員對我們網站的評價，了解他們的真實體驗。
          </PageTitle>
          <div className="mt-24 grid grid-cols-1 gap-12 gap-x-24 md:grid-cols-2 xl:grid-cols-4">
            {teamData.map(({ img, name, position }) => (
              <TeamCard
                key={name}
                img={img}
                name={name}
                position={position}

              />
            ))}
          </div>
        </div>
      </section>


      <section className="relative bg-white my-16 px-4">
        <div className="container mx-auto">
          <PageTitle section=" " heading="網站特色">
            擁有友善的介面和豐富的功能，讓你輕鬆瀏覽會員資料，快速找到志趣相投的人。
          </PageTitle>
          <div className="mx-auto mt-20  grid max-w-5xl grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
            {contactData.map(({ title, icon, description }) => (
              <Card
                key={title}
                color="transparent"
                shadow={false}
                className="text-center text-blue-gray-900"
              >
                <div className="mx-auto mb-6 grid h-14 w-14 place-items-center rounded-full bg-blue-gray-900 shadow-lg shadow-gray-500/20">
                  {React.createElement(icon, {
                    className: "w-5 h-5 text-white",
                  })}
                </div>
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  {title}
                </Typography>
                <Typography className="font-normal text-blue-gray-500">
                  {description}
                </Typography>
              </Card>
            ))}
          </div>
          <MemberFeature />

        </div>
      </section>
      <section className="my-16 px-4" >
        <PageTitle section=" " heading="立即加入">
          尋找適合您的對象
        </PageTitle>
        <div className="mx-auto w-full mt-12 lg:w-5/12">
          <div className="mb-8 flex flex-col gap-8">
            <ModalSignUp className="w-full" />
            <ModalSignIn className="w-full" />
          </div>
        </div>
      </section>
      <section className="mt-8 px-4">
      <PageTitle section=" " heading="會員計劃">
          選擇合適您的計劃，尋找心儀對象
        </PageTitle>
        <PricingSection/>
      </section>
    </>
  );
}

export default Home;
