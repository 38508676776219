import React from "react";
import {
  Input,
  Option,
  Select,
  Button,
  Dialog,
  Textarea,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export function ModalSignUp() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const onChange = ({ target }) => setEmail(target.value);
  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Button onClick={handleOpen} variant="gradient">
        註冊會員
      </Button>
      <Dialog size="sm" open={open} handler={handleOpen} className="p-4">
        <DialogHeader className="relative m-0 block">
          <Typography className="text-xl" variant="h3" color="blue-gray">
            註冊會員
          </Typography>

          <IconButton
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5"
            onClick={handleOpen}
          >
            <XMarkIcon className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody className="space-y-4 pb-6">
          <div>

            <Select
              label="性別"
              placeholder="1"

            >
              <Option>男</Option>
              <Option>女</Option>
            </Select>
          </div>
          <Input
            type="email"
            label="電郵"
            value={email}
            onChange={onChange}
            className="pr-20"
            containerProps={{
              className: "min-w-0",
            }}
          />

          <Input
            label="密碼"
            color="gray"
            name="name"
          />
          <Input
            label="確認密碼"
            color="gray"
            name="name"
          />
          <div className="relative flex w-full">
            <Input
              label="驗證碼"
              color="gray"
              name="name"
            />
            <Button
              size="sm"
              color={email ? "gray" : "blue-gray"}
              disabled={!email}
              className="!absolute right-1 top-1 rounded"
            >
              發送驗證碼
            </Button>
          </div>

        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="text" color="gray" onClick={handleOpen}>
            取消
          </Button>
          <Button className="ml-auto" onClick={handleOpen}>
            註冊
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}