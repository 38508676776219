import React from "react";
import { Typography } from "@material-tailwind/react";

const faqs = [
  {
    title: "男性會員：",
    desc: [
      {
        subheading: "付費驗證：",
        items: [
          "展現誠意： 付費驗證是男性會員展現誠意和認真交友的表現，讓女性會員更放心地與你交流。",
          "提升信賴度： 付費驗證可以提升女性會員對你的信賴度，讓你更容易獲得她們的青睞。",
          "優質會員： 付費驗證的男性會員通常更認真地尋找伴侶，更注重約會品質，讓女性會員更安心地與你約會。",
          "篩選優質對象： 付費驗證可以幫助女性會員篩選更優質的男性對象，提升約會的成功率。",
          "尊貴體驗： 付費驗證可以享受更尊貴的服務和體驗，例如優先匹配、更多曝光機會等，讓你更輕鬆地找到心儀的對象。"
        ]
      }
    ],
  },
  {
    title: "女性會員：",
    desc: [
      {
        subheading: "年齡驗證：",
        items: [
          "安全可靠： 嚴格執行年齡驗證，確保所有男性會員都符合使用規範，讓你安心地與心儀對象交流，避免不必要的困擾。",
          "安心交友： 年齡驗證讓你更安心地與符合年齡條件的男性交流，避免與不符合年齡條件的用戶接觸。",
          "真實可靠： 年齡驗證可以確保會員資料的真實性，讓你更安心地與其他會員交流，尋找真愛。",
          "保護未成年人： 年齡驗證是保護未成年人的重要措施，確保你不會接觸到不適當的對象。",
          "建立信任： 年齡驗證建立了信任基礎，讓你更放心地使用princessbookhk.com的服務，找到真愛。"
        ]
      },
      {
        subheading: "照片驗證：",
        items: [
          "真實身份： 照片驗證可以確保你與真實的男性交流，避免虛假身份和欺騙行為，讓你更安心地約會。",
          "提升信任度： 照片驗證可以提升你對男性會員的信任度，讓你更放心地與他們交流，開啟一段美好的愛情旅程。",
          "避免詐騙： 照片驗證可以有效避免詐騙行為，保障你的權益，讓你更安心地使用princessbookhk.com的服務。",
          "真實體驗： 照片驗證讓你更真實地了解男性會員，提升約會的成功率，讓你更容易找到心儀的對象。",
          "安全保障： 照片驗證是保障你安全的重要措施，讓你更放心地使用princessbookhk.com的服務，尋找真愛。"
        ]
      },

    ],
  },
];

export function MemberFeature() {
  return (
    <section className="mt-16">
      <div className="container mx-auto">
        <div className="mb-14 text-center ">
          <Typography
            variant="h1"
            color="blue-gray"
            className="mb-4 text-4xl !leading-snug lg:text-[40px]"
          >
            會員特點
          </Typography>
          <Typography
            className="mx-auto font-normal text-[18px] !text-gray-500 lg:max-w-3xl"
          >
            男性和女性會員各有不同要求，令配對過程更有保證。
          </Typography>
        </div>
        <div className="mx-auto grid gap-10">
          {faqs.map(({ title, desc }) => (
            <div key={title}>
              <Typography color="blue-gray" className="pb-6 text-[20px] font-bold">
                {title}
              </Typography>
              <div className="border-t border-gray-200 pt-4">
                {desc.map((item, index) => (
                  <div key={index}>
                    {item.subheading && (
                      <Typography
                        color="blue-gray"
                        className="pb-2 text-[18px] font-bold"
                      >
                        {item.subheading}
                      </Typography>
                    )}
                    <ul className="list-disc list-inside font-normal !text-gray-500">
                      {item.items &&
                        item.items.map((subItem, subIndex) => (
                          <li key={subIndex}>{subItem}</li>
                        ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MemberFeature;